import axios from 'axios';
import { BASE_URL } from '../constant';

export const doLogin = (username, password, onComplete) => {
    let url = BASE_URL + 'login';
    let params = JSON.stringify({
        username: username, password: password
    });

    axios.post(url, params, 
    {
        headers: {
            "content-type": "application/json",
        },
        validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
        }
        
    })
    .then((resp) => {
        let status = false;
        let token = null;
        let userInfo = null;
        let message = '';
        if (resp.hasOwnProperty('data'))
        {
            let data = resp['data']; 
            if (data.hasOwnProperty('status')){
                status = data.status;
            }
            if (data.hasOwnProperty('message')){
                message = data.message;
            }
            if (data.hasOwnProperty('data')){
                let data2 = data.data;
                token = data2?.token;
                userInfo = data2?.user;
            }
        }
        onComplete(status, token, userInfo, message);
    }, 
    (err) => {
        onComplete(false, null, null, err.message)
    })
    .catch((exp) => {
        onComplete(false, null, null, exp.message)
    })

}

function getAuthHeader(token) {
    return {
        "Authorization" : "Bearer "+ token
    }
}

export const doLogout = (token, onLogout) => {
    let url = BASE_URL + 'auth/logout';
    axios.post(url, 
        {},  
        { headers: getAuthHeader(token) }
    ).then ((resp) => { 
        onLogout(); 
    })
    .catch ((err) => {
        alert('Logout: ' + err);
        onLogout(); 
    });
}

export const doGetWorkCategories = (token, onGetWorkCategories) => {
    let url = BASE_URL + 'work-categories';
    axios.get(url, { headers: getAuthHeader(token)})
        .then ((resp) => { onGetWorkCategories(resp)}, (err) => { console.log(JSON.stringify(err))});
}

export const doGetAttendanceHistory = (token, range, onGetAttendance, onFail) => {
    let url = BASE_URL + 'report/user/attendance-history?range=' + range;
    axios.get(url, { headers: getAuthHeader(token)})
        .then ((resp) => { onGetAttendance(resp)})
        .catch ((err) => { onFail(err)});
}

const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

const createFormData = (photo, latlong, cat_id, shift_counter) => {
    const formdata = new FormData();
  
    const blob = dataURItoBlob(photo);

    formdata.append('image', blob)
    formdata.append('latlong', latlong);
    formdata.append('work_category_id', cat_id );
    formdata.append('shift_counter', shift_counter);
    return formdata;
};

export const doCheckin = (token, image, latlong, cat_id, shift_counter, onCheckin, onFailCheckin) => {

    //console.log('CHECKIN', image, latlong, cat_id, shift_counter);

    let url = BASE_URL + 'attendance/checkin';
    let formdata = createFormData(image, latlong, cat_id, shift_counter);

    axios.post(url, formdata, {
        'headers': {
            'Content-Type': 'multipart/form-data', 
            ...getAuthHeader(token)
        },
        validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
        }
    })
    .then((resp) => { onCheckin(resp) }, (reason) => { onFailCheckin(reason) })
    .catch((reason) => { onFailCheckin(reason) } );
}

export const doCheckout = (token, image, latlong, cat_id, shift_counter, onCheckout, onFailCheckout) => {
    let url = BASE_URL + 'attendance/checkout';
    let formdata = createFormData(image, latlong, cat_id, shift_counter);

    axios.post(url, formdata, {
        'headers': {
            'Content-Type': 'multipart/form-data', 
            ...getAuthHeader(token)
        },
        validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
        }
    })
    .then ((resp) => { onCheckout(resp) }, (reason) => { onFailCheckout(reason) } )
    .catch((reason) => { onFailCheckout(reason) } );
}

export const doGetFolderList = (token, onSuccess, onFail) => {
    let url = BASE_URL + 'documents/category-list';
    axios.get(url, { 
        headers: getAuthHeader(token),
        validateStatus: (status) =>  {return status<500}
    })
        .then ((resp) => { onSuccess(resp)}, (err) => { onFail(err)});
}

export const doGetFileList = (token, category, onSuccess, onFail) => {
    let url = BASE_URL + 'documents/category/file-list';
    axios.get(url + '?key='+category, { 
        headers: getAuthHeader(token),
        validateStatus: (status) =>  {return status<500}
    })
        .then ((resp) => { onSuccess(resp)}, (err) => { onFail(err)});
}

export const doGetArticleList = (token, onSuccess, onFail) => {
    let url = BASE_URL + 'articles';
    axios.get(url, { 
        headers: getAuthHeader(token),
        validateStatus: (status) => {return status<500}
    })
        .then ((resp) => { onSuccess(resp)}, (err) => { onFail(err)});
}

export const doGetCurrentAttendance = (token, onSuccess, onFail) => {
    let url = BASE_URL + 'attendance/user/current-attendance';
        axios.get(url, { 
            headers: getAuthHeader(token),
            validateStatus: (status) => {return status<500}
        })
        .then ((resp) => { onSuccess(resp)})
        .catch ((err) => { onFail(err)});
  
}


export const doGetCurrentUserInfo = (token, onSuccess, onFail) => {
    let url = BASE_URL + 'auth/me';
    axios.get(url, { 
        headers: getAuthHeader(token),
        validateStatus: (status) => {return status<500}
    })
    .then ((resp) => { onSuccess(resp)})
    .catch ((err) => { onFail(err)});

}

export const doGetShiftByCategory = (token, cat_id, onSuccess, onFail) => {
    let url = BASE_URL + 'shift/user?work_category_id='+cat_id;
        axios.get(url, { 
            headers: getAuthHeader(token),
            validateStatus: (status) => {return status<500}
        })
        .then ((resp) => { onSuccess(resp)})
        .catch ((err) => { onFail(err)});
}



