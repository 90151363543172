import React from 'react';

// create a component
const SelectButtons = ({data, selected, onChange, enabled, prefix, optionLabel, idField='id'}) => {

    const findItemId = (id, list) => {
        if (!list) {
            return null;
        }
        if (idField) {
            return list.findIndex((entry) => {
                return entry[idField] === id;
            })
        }
        return list.findIndex((entry) => {
            return entry.id === id;
        });
    }

    const labelFormat = (item) => {
        return (optionLabel? optionLabel(item) : item.id);
    };

    const btnClicked = (value) => {
        if (onChange)
        {
            onChange(value);
        }
    }

    let selectedIndex = findItemId(selected, data);

    const isSelected = (item) => {
        if (idField) {
            return item[idField] === selected;
        }
        else{
            return item.id === selected;
        };
    }

    if (!enabled) {
        let option = data[selectedIndex];
        return (
            <div>{ (option? labelFormat(option) : 'not selected') }</div>
        )
    }
    
    if (!data || !Array.isArray(data) || (data.length === 0)) {
        return (
            <div>Empty</div>
        )
    }

    return (
        <div className="btn-group" role="group" aria-label="Basic radio button group">
            {
             data.map((option, key) => {
                return (<div key={prefix+key} >
                    <input type="radio" className="btn-check" name={"btnradio-"+prefix} 
                        id={"btnradio-"+prefix+key} autoComplete="off" 
                        
                        onClick={ () => {btnClicked(option)} } 
                        defaultChecked={ isSelected(option) }  />
                    <label className="btn btn-outline-primary" htmlFor={"btnradio-"+prefix+key}>
                         {labelFormat(option)}
                    </label>
                    </div>
                );
            })
            }

        </div>
    );
};

//make this component available to the app
export default SelectButtons;
