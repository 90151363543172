import { useContext, useEffect, useState } from "react";
import UserContext from "../hooks/UserContext";
import { doGetCurrentUserInfo, doLogout } from "../api/client";
import { Navigate } from "react-router-dom";

const imgPic = require('../img/pic.png');
const imgLogout = require('../img/logout.png');

export const Profile = () => {
    const { userInfo, setUserInfo, token } = useContext(UserContext);
    const [ navigate, setNavigate ] = useState(false);


    const handleLogout = () => {
        doLogout(token, () => {
            setNavigate(true);
        } );
    }
    
    useEffect(() => {
        doGetCurrentUserInfo(token, (resp) => {
            setUserInfo(resp.data.data);
        }, 
        (err) => {});

    });

    if (navigate) {
        return <Navigate to="/login"/>;
    }

    return (
        <div className="form-signin mt-1">
            {/* <LogoBanner label="Profil" /> */}
            <div>
                <div className="h3">Informasi Pengguna</div>
                <img
                    src={ ( (userInfo?.avatar) ? userInfo?.avatar : imgPic ) }
                    style={{width: 300, height: 300, borderRadius: 300/ 2}} 
                    alt="avatar"
                    />
                <div className="h5 mt-3">
                    Nama: <b>{userInfo?.name}</b>
                </div>
                <div className="h5 ">
                    Department: <b>{userInfo?.department?.name}</b>
                </div>
                <div className="h5 ">
                    Branch: <b>{userInfo?.branch?.name}</b>
                </div>
                <div className="h5 ">
                    Project: <b>{userInfo?.project?.name}</b>
                </div>
                <div className="h5 mb-5">
                    Status: <b>{userInfo?.status}</b>
                </div>
            </div> 
            <button onClick={handleLogout} className="w-100 btn btn-large btn-warning text-black">
                    <img src={imgLogout} className="mx-2" alt="log out" />
                    Log out dari Aplikasi
            </button>
        </div>
    );

};

