import {Navigate} from "react-router-dom";
import {useContext, useRef, useState} from "react";
import { doLogin } from "../api/client";
import UserContext from "../hooks/UserContext";

const cover = require("../img/cover.png");
// const imgShow = require("../img/show.png");
// const imgHide = require("../img/hide.png");

export const Login = () => {
    const [navigate, setNavigate] = useState(false);
    const { setUserInfo, setToken } = useContext(UserContext);
    const loginRef = useRef();
    const passRef = useRef();

    const onLogin = (success, token, user, msg) =>  {
        if (success) {
            // login success 
            setToken(token);
            setUserInfo(user);
            setNavigate(true);
        }
        else {
            alert('Login Failed, '+ msg);
        }
      }  
    
    const submit = async e => {
        e.preventDefault();
        let login = loginRef.current.value;
        let pass = passRef.current.value;
        doLogin(login, pass, onLogin);
    }

    if (navigate) {
        return <Navigate to="/"/>;
    }

    return <main className="form-signin">
        <form onSubmit={submit}>
            <img src={cover} className="mb-2" width={300} height={300} alt="" />
            <p className="h4 mb-3 fw-normal">Please Log in to Continue</p>

            <div className="form-floating">
                <input id="floatingInput" type="text" className="form-control" ref={loginRef} placeholder="Enter User Name" />
                <label htmlFor="floatingInput">User Name / Email</label>
            </div>

            <div className="form-floating">
                <input type="password" id="floatingPassword" className="form-control" ref={passRef} placeholder="Enter Password" />
                <label htmlFor="floatingPassword">Password</label>
            </div>

            <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
        </form>
    </main>
}