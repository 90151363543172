import React, { createContext } from 'react';
import { useLocalStorage } from './useLocalStorage';

const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const [userInfo, setUserInfo] = useLocalStorage('user');  
    const [token, setToken] = useLocalStorage('token');

    return (
        <UserContext.Provider value={{ 
            userInfo, setUserInfo,
            token, setToken
        }}>
            {children}
        </UserContext.Provider>
    );
}


export default UserContext;