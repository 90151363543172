import { Outlet } from "react-router-dom"
const logoPancaduta = require("../img/logopancaduta.png");

export const LoggedOutLayout = () => {
return (
    <div>
        <header className="p-2 header">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <img src={logoPancaduta} style={{width:40, height:40 }} alt=""/> 
                <div className="nav col-12 col-lg-auto me-lg-auto justify-content-center h5 mb-md-0 mx-3">
                    PANCA DUTA PRAKARSA
                </div>

                <div className="text-end">
                    {/*  */}
                </div>
            </div>
        </header>
        <Outlet/>
    </div>
);

}