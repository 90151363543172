import { useContext } from "react";
import { Link, Outlet } from "react-router-dom"
import UserContext from "../hooks/UserContext";

export const LoggedInLayout = () => {
    const logoPancaduta = require("../img/logopancaduta.png");
    const imgPic = require('../img/pic.png');
    const { userInfo } = useContext(UserContext);

    return (
        <div>
            <header className="px-2 bg-dark text-white">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <div className="nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0">
                        <Link to="/" className="nav-link px-2 text-white">
                            <img src={logoPancaduta} style={{width:40, height:40 }} alt="Home"/> 
                            <span className="h5 mx-3">
                                PANCA DUTA PRAKARSA
                            </span>
                        </Link>
                    </div>

                    <div className="text-end">
                        <Link to="/profile" className="btn text-white">
                            { (userInfo?.name ? userInfo.name : 'Not Logged In') }
                            <img
                                src={ ( (userInfo?.avatar) ? userInfo?.avatar : imgPic ) }
                                style={{width: 30, height: 30, borderRadius: 30/ 2, marginLeft:'8px'}}
                                alt="Profile" 
                            />
                        </Link>
                    </div>
                </div>
            </header>
            <Outlet/>
        </div>
    );

}