import React, { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import UserContext from '../hooks/UserContext';
import { durationOf, formatDateOnly, formatLatLong, formatTimeOnly, getDateTimeNow, parseDateTime,
     } from '../lib/formatting';
import { doCheckin, doCheckout, doGetCurrentAttendance, doGetWorkCategories } from '../api/client';
import { MapWrapper } from '../components/MapWrapper';
import { useGeolocated } from 'react-geolocated';
import ImageViewer from 'react-simple-image-viewer';
import Webcam from "react-webcam";
import SelectButtons from '../components/SelectButtons';

const timerstart = require('../img/timerstart.png');
const timerstop  = require('../img/timerstop.png');
const timelength = require('../img/timelength.png');
const checkin = require('../img/checkin.png');
const checkout = require('../img/checkout.png');
const imgSuccess = require('../img/success.png');
const imgFail = require('../img/failed.png');
const noPic = require('../img/pic.png');
//const imgReport = require('../img/report.png');
const imgCamera = require('../img/camera.png');
const imgClose = require('../img/close.png');
const imgBullet = require('../img/bullet.png');

const defaultWorkCategories = [
    {name: 'Normal', id: 1, shifts:null},
    {name: 'Overtime', id: 3, shifts:null},
    {name: 'Travel', id: 2, shifts:null}
];
// const defaultWorkShifts = [
//     {shift_counter: 1, shift_label: 'shift1'}
// ];

export const Attendance = () => {
    const [ checkinTime, setCheckinTime ] = useState(null);
    const [ checkoutTime, setCheckoutTime ] = useState(null);

    const [ workCategories, setWorkCategories ] = useState(defaultWorkCategories);
    const [ workCategoriesLoaded, setWorkCategoriesLoaded ] = useState(false);

    const [ categoryId, setCategoryId ] = useState(0);
    const [ shiftNumber, setShiftNumber ] = useState(0);
    const [ daftarShift, setDaftarShift ] = useState([]);

    const [ photoFormVisible, setPhotoFormVisible ] = useState(false);
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ modalMessage, setModalMessage ] = useState('');
    const [ lastOperation, setLastOperation ] = useState('');

    const [ infoVisible, setInfoVisible ] = useState(false);
    const [ isViewerOpen, setIsViewerOpen ] = useState(false);
    const [ viewerIndex, setViewerIndex ] = useState(0);
    const [ checkinPhoto, setCheckinPhoto ] = useState(null);
    const [ checkoutPhoto, setCheckoutPhoto ] = useState(null);

    const { token } = useContext(UserContext);
    const [ needUpdateCheckinInfo, setNeedUpdateCheckinInfo ] = useState(true);

    const updateCheckinInfo = () => {
        if (token != null){
            
            doGetCurrentAttendance(token,
            (resp) => {
                let data2 = resp.data?.data;

                let checkinDT = data2?.checkin_time;
                setCheckinTime(checkinDT? parseDateTime(checkinDT) : null);

                let checkoutDT = data2?.checkout_time;
                setCheckoutTime(checkoutDT? parseDateTime(checkoutDT) : null);

                let cat_id = data2?.work_category_id;
                setCategoryId(cat_id);

                let shift_counter = data2?.shift_counter;
                setShiftNumber(shift_counter);               

                let checkin_url = data2?.checkin_image;
                setCheckinPhoto(checkin_url);

                let checkout_url = data2?.checkout_image;
                setCheckoutPhoto(checkout_url);

                console.log('ATTENDANCE',JSON.stringify(data2));
                setNeedUpdateCheckinInfo(false);                
            },
            (err) => {console.log(JSON.stringify(err))}
            );
        }
    };
    
    
    const loadCurrentWorkShifts = () => {
        if (workCategoriesLoaded !== true) {
            doGetWorkCategories (token, (resp) => {
                if (resp.data){
                    setWorkCategories (resp.data?.data);
                }
                setWorkCategoriesLoaded(true);
                //console.log('LOADED SHIFTS', JSON.stringify(resp.data?.data));
            });
        }
    };

    const isInWork = () => {
        return (checkinTime != null) && (checkoutTime == null);
    }

    //const DELAY = 30000;

    useEffect(() => {
        //console.log('EFFECT');
        loadCurrentWorkShifts();
        updateCheckinInfo();

        // const fn = setInterval(() => {}, DELAY);
        // return () => {
        //     clearInterval(fn);
        // }
    }, []);


    useEffect(()=>{
        //console.log('EFFECT UPDATE');
        if (needUpdateCheckinInfo){
            updateCheckinInfo();
            //console.log("UPDATED!!!");
        }
    }, [needUpdateCheckinInfo ] );

    const buttonCheckinPressed = () => {
        setLastOperation('Check In');
        syncCategoryAndShift();
        takePhoto();
    }

    const buttonCheckoutPressed = () => {
        setLastOperation('Check Out');
        takePhoto();
    }

    const syncCategoryAndShift = () => {
        //console.log('SYNC CATEG SHIFT', categoryId, shiftNumber);
        //console.log('LOADED SHIFTS (SYNC)', JSON.stringify(workCategories));
        // setShiftMessage('');
        if (workCategoriesLoaded) {
            // when empty, pick first one
            let cat_id = categoryId;
            let shift_counter = shiftNumber;

            if (cat_id == null){
                if (workCategories[0]){
                    cat_id = workCategories[0].id;
                    setCategoryId(cat_id);
                }
            }

            workCategories.forEach( (categ) => {
                if (categ.id === cat_id) {
                    setDaftarShift(categ.shifts);
                    //console.log('CAT', categ);                    
                }
            });
            
            if (shift_counter == null) {
                if (daftarShift[0]){
                    shift_counter = daftarShift[0]?.shift_counter;
                    setShiftNumber(shift_counter);
                }
            }
        }
    }

    const takePhoto = () => {
        setImgSrc(null);
        setPhotoFormVisible(true);
    }

    const workDuration = () => {
        if (checkinTime != null) {
            if (checkoutTime != null){
                return durationOf(checkinTime, checkoutTime);
            }
            else {
                return '--:--';
            }
        }
        else {
            return '--:--'
        }
    }

    const handleCategoryChange = (item) => {
        setCategoryId(item.id);
        // setShiftMessage('');
        setDaftarShift(item.shifts);
    }

    const handleShiftChange = (number) => {
        setShiftNumber(number); 
    }

    const submitCheckinCheckout = () => {
        let photoAsset = imgSrc;
        let latlong = formatLatLong(
            coords.latitude, 
            coords.longitude
        ); 
        let cat_id = categoryId;
        let shift_counter = shiftNumber;

        if (isInWork() === false) {
            doCheckin(token, photoAsset, latlong, cat_id, shift_counter,
                (resp) => {
                    let status = resp.data?.status;
                    setSuccess(status === true);
                    setModalMessage(resp.data?.message);
                    if (status === true){
                        setCheckinTime(getDateTimeNow());
                        setCheckoutTime(null);
                        setNeedUpdateCheckinInfo(true);
                    }
                    //console.log(resp);
                    setPhotoFormVisible(false);
                    setModalVisible(true);
                }, 
                (err) => {
                    console.log(JSON.stringify(err));
                    setSuccess(false);
                    setModalMessage(err.message);
                    setPhotoFormVisible(false);
                    setModalVisible(true);
                });
        }
        else {
            doCheckout(token, photoAsset, latlong, cat_id, shift_counter,
                (resp) => {
                    let status = resp.data?.status;
                    setSuccess(status === true);
                    setModalMessage(resp.data?.message);
                    if (status === true){
                        setCheckoutTime(getDateTimeNow());
                        setNeedUpdateCheckinInfo(true);
                    }
                    //console.log(resp.data);
                    setPhotoFormVisible(false);
                    setModalVisible(true);
                }, 
                (err) => {
                    console.log(JSON.stringify(err));
                    setSuccess(false);
                    setModalMessage(err.message);
                    setPhotoFormVisible(false);
                    setModalVisible(true);
                });

        }
    };

 
    const styles = {
        // mainPanel: {
        //     width: '100%',
        //     height: '40lv'
        // }
    };
        
    const toggleInfoPanel = () => {
        setInfoVisible( !infoVisible );
    }

    const checkinImages = () => {
        return [ checkinPhoto, checkoutPhoto ];
    }

    const closeImageViewer = () => {
        setIsViewerOpen(false);
    }

    const openImageViewer = (index) => {
        setViewerIndex(index)
        setIsViewerOpen(true);
    }
    const isImageOpen = (index) => {
        return isViewerOpen && (viewerIndex === index)
    }

    const DetailInfo = (props) => {
        if (props.shown) 
        {
            if (checkinTime != null) {
            return (
            <div className='container-lg'>
                <p className='h5'>Attendance Detail</p>
                <div className='row'>
                    <div className='col col-auto mt-2'>
                        <img src={imgBullet} style={{width:30, height:30}} alt='Check In'/>
                        <span className='h6'> Check In </span>
                        <span className='h4'> { formatTimeOnly(checkinTime) } </span>
                    </div>
                    <div className='col'>
                        <img src={checkinPhoto} 
                        style={{ height:45, margin:5}} 
                        alt='Foto Check In' 
                        onClick={ () => openImageViewer(0)}
                        />
                        {isImageOpen(0) && (
                            <ImageViewer
                            src={checkinImages()}
                            currentIndex={0}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)"
                            }}
                            closeOnClickOutside={true}
                            />
                        )}
                    </div>
                </div>
                { checkoutTime ? 
                (<div className='row'>
                    <div className='col col-auto mt-2'>
                        <img src={imgBullet} style={{width:30, height:30}} alt='Check Out'/>
                        <span className='h6'> Check Out </span>
                        <span className='h4'> { formatTimeOnly(checkoutTime) } </span>
                    </div>
                    <div className='col'>
                    <img src={checkoutPhoto} 
                        style={{ height:45, margin:5}} 
                        alt='Foto Check Out' 
                        onClick={ () => openImageViewer(1)}
                        />
                        {isImageOpen(1) && (
                            <ImageViewer
                            src={checkinImages()}
                            currentIndex={1}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)"
                            }}
                            closeOnClickOutside={true}
                            />
                        )}
                    </div>
                </div>)
                : (<div />)
                }
            </div>
            )}
            else {
                return (
                    <div className='container-lg'>
                        <p className='h5 text-center'>NO DETAIL</p>
                        <p className='text-center'>Please Check in First</p>
                    </div>
                );
            }
        };
    }

    const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled, positionError } = 
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        watchPosition: true,
        userDecisionTimeout: 5000,
        watchLocationPermissionChange: true,
    });

    const closePhotoForm = () => { setPhotoFormVisible(false); }

    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);
  
    const capture = React.useCallback(() => {
        const capturedSrc = webcamRef.current.getScreenshot();
        setImgSrc(capturedSrc);
    }, [webcamRef, setImgSrc]);


    const videoConstraints = {
        width: 720,
        height: 1280,
        facingMode: "user"
    };

    ReactModal.setAppElement('#root');
    return <div id='root' className="map-form mt-2">

            <ReactModal 
                isOpen={photoFormVisible}            
                style={{
                    overlay: {
                      backgroundColor: 'transparent',
                    },
                    content: {
                      borderWidth: 1,
                      borderColor: 'black',
                      maxWidth: 1280,
                      alignSelf: 'center'
                    }
                  }}
      
            >
                <div className='row'>
                    <div className='col'>
                        <span className='h3'> { lastOperation } </span>
                    </div>
                    <div className='col col-auto'>
                        <button className='btn btn-light' onClick={closePhotoForm}>
                            <img src={imgClose} alt='Close'/>
                        </button>
                    </div>
                </div>

                <div className='row'>
                    <div className='col col-xs-6 text-center'>
                        <p>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints} 
                            style={{width:"360px", height:"540px"}}
                        />
                        </p>
                        <p>
                        <button onClick={capture} 
                        className='btn btn-outline-dark text-center' 
                        style={{ minWidth:'360px'}}>
                            <img src={imgCamera} style={{width:30, height:30}} alt='Camera'/>
                            <span className='h5 m-3'>Take photo</span>
                        </button>
                        </p>
                    </div>
                    <div className='col col-xs-6 text-center d-flex flex-column justify-content-end'>
                        <div className='d-flex flex-row justify-content-center'>
                        { imgSrc ? 
                        <img src={imgSrc} alt='Hasil foto' /> 
                        : <img src={noPic} alt='Belum ada foto' style={{width:30, height:30}}/>
                        }
                        </div>
                        <p className='h5 text-center text-muted m-5'>
                            { imgSrc ? 'Photo OK' : 'Take photo first' }
                        </p>
                    </div>
                </div>

                <div className='row m-3'>
                    <div className='col col-xs-3 col-sm-2'>
                        Work Category
                    </div>
                    <div className='col col-xs-9 col-sm-10'>
                        <SelectButtons 
                            prefix="cat"
                            data={workCategories} 
                            selected={categoryId} 
                            optionLabel={(item)=>item.name}
                            onChange={handleCategoryChange} 
                            enabled={(lastOperation === 'Check In')}/>
                    </div>
                </div>
                <div className='row m-3'>
                    <div className='col col-xs-3 col-sm-2'>
                        Shift
                    </div>
                    <div className='col col-xs-9 col-sm-10'>
                        <SelectButtons 
                            prefix="shift"
                            data={daftarShift} 
                            selected={shiftNumber} 
                            optionLabel={(item)=>('Shift ' + item.shift_counter)}
                            onChange={(item) => {handleShiftChange(item.shift_counter)}} 
                            idField='shift_counter'
                            enabled={(lastOperation === 'Check In')}/>
                    </div>
                </div>
                <div className='row m-3'>
                    <div className='col col-12 text-center'>
                        {  (!imgSrc) || (!shiftNumber)  ? 'Lengkapi dulu foto dan shift' : null }
                    </div>
                    <div className='col col-12 text-center'>
                        <button 
                        className='btn btn-primary' 
                        style={{minWidth:160}} disabled={( (!imgSrc) || (!categoryId) || (!shiftNumber) || (!coords) )} 
                        onClick={ submitCheckinCheckout }>
                            <span className='h4'>Lakukan {lastOperation }</span>
                        </button>
                    </div>
                </div>
            </ReactModal> 


            <ReactModal
                isOpen={modalVisible}
                style={{
                    overlay: {
                      backgroundColor: '#40000000'
                    },
                    content: {
                      borderWidth: 1,
                      borderColor: 'black',
                      maxWidth: 640,
                      alignSelf: 'center'
                    }
                  }}
            >
                <div className='container text-center'>
                    <div className='h3'>
                        { lastOperation }
                        { success? ' Success': ' Failed' }
                    </div>
                    <div className='row' >
                        <div className='col col-12'>
                            { success ? ( <img src={imgSuccess} alt="Success" style={{width:30, height:30}}/> ) :
                            (<img src={imgFail} alt='Fail' style={{width:30, height:30}}/> )}
                        </div>
                    </div>
                    <div className='row' >
                        <div className='h5'> { modalMessage } </div>
                    </div>
                    <div className='row m-4' >
                        <div className='col col-12 d-flex flex-row justify-content-center'>
                            <img src={imgSrc? imgSrc  : noPic} alt='Foto' style={{width:240}}/>
                        </div>
                    </div>
                    <div className='row' >
                        <button
                            className='btn btn-success'
                            onClick={() => setModalVisible(!modalVisible)}
                        >
                            <div className='h5'>OK</div>
                        </button>
                    </div>
                </div>
            </ReactModal>

            <p className='h3 text-center'>Attendance</p>
            <div className="m-8 font-bold text-center">
                    {!isGeolocationAvailable ? (
                        <div>Your browser does not support Geolocation.</div>
                    ) : !isGeolocationEnabled ? (
                        <div>
                            <p>Geolocation is not enabled.</p>
                            <button className='btn btn-light' onClick={()=>{window.location.reload()}}>Reload</button>
                        </div>
                    ) : coords ? (
                        <div className='text-muted'>LAT {coords.latitude} LONG {coords.longitude} </div>
                    ) : (
                        <div>Getting the location data&hellip;</div>
                    )
            }</div>

            { ( coords ? 
                <MapWrapper lat={coords.latitude} lng={coords.longitude} zoom={18}/> : 
            null ) }

            <div className='container-lg p-3'>
                <div className="row my-2">
                    <div className='col'>
                        <div className='h2 text-start'>
                            { formatTimeOnly(getDateTimeNow()) + ' - ' + formatDateOnly(getDateTimeNow()) }
                        </div>
                        <div className='text-start'> 
                        {positionError ? positionError :
                        (coords ? 'Lat: '+coords.latitude+' Lng: '+coords.longitude : '--')} 
                        </div>
                    </div>
                    <div className='col col-auto'>
                        { (isInWork() === false ) ?
                        (<button onClick={buttonCheckinPressed} className='btn btn-orange' disabled={!coords}>
                                <img src={checkin} alt='Checkin Button' style={{width:27, height:27}}/>
                                <span className='h4 text-white mx-3'>Check In</span>
                        </button>) :
                        (<button onClick={buttonCheckoutPressed} className='btn btn-orange-outline' disabled={!coords}>
                            <img src={checkout} alt='Checkout Button' style={{width:27, height:27}}/>
                            <span className='h4 mx-3'>Check Out</span>
                        </button>) }
                    </div>
                </div>
                <div className='row my-2'>
                    <div className='col col-xs-4'>
                        <div className='row'>
                            <div className='col col-auto'>
                                <img src={timerstart} alt='Check In' />
                            </div>
                            <div className='col'>
                                <p> Check In </p>
                                <p className='h5'> { formatTimeOnly(checkinTime) } </p>
                            </div>
                        </div>
                    </div>
                    <div className='col col-xs-4'>
                        <div className='row'>
                            <div className='col col-auto'>
                                <img src={timerstop} alt='Check Out' />
                            </div>
                            <div className='col'>
                                <p> Check Out </p>
                                <p className='h5'> { formatTimeOnly(checkoutTime) } </p>
                            </div>
                        </div>
                    </div>
                    <div className='col col-xs-4'>
                        <div className='row'>
                            <div className='col col-auto'>
                                <img src={timelength} alt='Work time' />
                            </div>
                            <div className='col'>
                                <p> Work Time </p>
                                <p className='h5'> { workDuration() } </p>
                            </div>
                        </div>
                    </div>
                </div>

                <DetailInfo shown={ infoVisible } />
                <div className='row my-3'>   
                    <button onClick={toggleInfoPanel} className='btn btn-outline-secondary'>
                        <span className='h5'>
                            { infoVisible? 'Hide' : 'View' } Attendance Details
                        </span>
                    </button>
                </div>
            </div>

    </div>
}


