import { DateTime, Interval } from 'luxon';

export const durationOf = (startTime, endTime) => { 
    if ((startTime != null) && (endTime != null)) {
        let intv = Interval.fromDateTimes(startTime,endTime);
        let duration = intv.toDuration(['hours','minutes']);
        return duration.toFormat("h'h' mm'm'");
    }
    return '--:--';
}

export const getDateTimeNow = () => DateTime.now();
export const formatDateOnly = (datetime) => {
    if (datetime != null) {
        return datetime.toFormat("EEE, MMM dd");
    }
    else return null;
}
export const formatDateFullShort = (datetime) => {
    if (datetime != null) {
        return datetime.toFormat("MMM dd, yyyy");
    }
    else return null;
}
export const formatDateFull = (datetime) => {
    if (datetime != null) {
        return datetime.toFormat("EEE MMM dd, yyyy");
    }
    else return null;
}
export const formatTimeOnly = (datetime) => {
    if (datetime != null) {
        return datetime.toFormat("HH':'mm");
    }
    else return '--:--'
}
export const isSameDay = (date1, date2) => {
    if ((date1 == null) || (date2 == null)) {
        return false;
    }
    else {
        return date1.hasSame(date2, 'day');
    }
}

export const formatIsoDateTime = (datetime) => datetime.toISO({ includeOffset: false }) ;
export const parseIsoDateTime = (iso) => { 
    if (iso != null){
        return DateTime.fromISO(iso);
    }
    return null;
}

export const parseDateTime = (str) => {
    if (str != null){
        return DateTime.fromSQL(str);
    }
    return null;
}
export const formatLatLong = (lat, long) => {
    let lat1 = Number.parseFloat(lat).toFixed(5);
    let lon1 = Number.parseFloat(long).toFixed(5);
    return '' + lat1 + ',' + lon1;    
};

export const reformatDateShort = (str) => {
    let datetime = parseDateTime(str);
    if (datetime != null) {
        return datetime.toFormat("dd MMM yyyy");
    }
    else return '-'
}

export const reformatDayOnly = (str) => {
    let datetime = parseDateTime(str);
    if (datetime != null) {
        return datetime.toFormat("EEE");
    }
    else return '-'
}

