import React, { useContext, useEffect, useState } from 'react';
import { doGetAttendanceHistory } from '../api/client';
import UserContext from '../hooks/UserContext';
import { durationOf, formatTimeOnly, parseDateTime, reformatDateShort, reformatDayOnly } from '../lib/formatting';


export const Reports = () => {

    // const [selectedCategory, setSelectedCategory ] = useState('Normal Attendance');

    const { token } = useContext(UserContext);
    const [ listItems, setListItems ] = useState([]);

    // const ranges = [ 'daily', 'weekly', 'monthly' ];
    // const categories = [ 'Normal Attendance', 'Perjalanan Dinas' ];

    //  const [ workCategories, setWorkCategories ] = useState([]);

    useEffect(() => {
        // doGetWorkCategories (token, (resp) => {
        //     if (resp.data){
        //         setWorkCategories (resp.data?.data);
        //     }
        // });

        // let range = ranges[selectedIndex];

        let range = 'monthly';
        doGetAttendanceHistory(token, range, (resp) => {
            if (resp.data) {
                let data2 = resp.data?.data;
                setListItems(data2);
                //console.log(resp.data.data);
            }
        }, 
        (err) => {
            console.log(JSON.stringify(err));
        })

    }, [token]);


    const ListRows = () => {

        return ( 
            listItems 
            ? listItems.map( (item, key) => {
                return <tr key={key}>
                    <th scope='row'> 
                        { reformatDayOnly(item.checkin_time) }, &nbsp;  
                        { reformatDateShort(item.checkin_time) }
                    </th>
                    <td>{ formatTimeOnly(parseDateTime(item.checkin_time)) }</td>
                    <td>{ formatTimeOnly(parseDateTime(item.checkout_time)) }</td>
                    <td>{ durationOf(parseDateTime(item.checkin_time), parseDateTime(item.checkout_time)) }</td>
                    <td>
                        { (item.checkin_gap_time > 0 ? (
                            <span style={{color:'red' }}>Late</span>
                        ) : (
                            <span style={{fontWeight:500}}>On Time</span>
                        ))  }
                    </td>
                    <td>
                        { ( item.project ? item.project?.name : 'NO PROJECT' ) }
                    </td>
                </tr>
            } )
            : <tr><td colSpan={6}> -- Kosong --</td></tr> 
        ); 
    }

    return <div className='report-form'>
        <h3>Attendance Report</h3>

        <table className='table'>
            <thead>
            <tr>
                <th scope='col'>Date</th> 
                <th scope='col'>Checkin Time</th> 
                <th scope='col'>Checkout Time</th> 
                <th scope='col'>Work Time</th> 
                <th scope='col'>Status</th> 
                <th scope='col'>Project Name</th> 
            </tr>
            </thead>
            <tbody>
                <ListRows />
            </tbody>
       </table>
    </div>;
}