import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserProvider } from './hooks/UserContext';
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { Profile } from './pages/Profile';
import { Attendance } from './pages/Attendance';
import { Reports } from './pages/Reports';
import { LoggedOutLayout } from './components/LoggedOut';
import { LoggedInLayout } from './components/LoggedIn';
import { Unassigned } from './pages/Unassigned';

function App() {
    return <UserProvider>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoggedInLayout/>}>
              <Route path="/" element={<Home/>}/>
              <Route path="/attend" element={<Attendance/>}/>
              <Route path="/report" element={<Reports/>}/>
              <Route path="/profile" element={<Profile/>}/>
            </Route>
            <Route path='/login' element={<LoggedOutLayout/>}>
              <Route path="/login" element={<Login/>}/>
            </Route>
            <Route path='*' element={<Unassigned/>} />
        </Routes>
      </BrowserRouter>
    </UserProvider>;
}

export default App;
