import {useContext, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import UserContext from "../hooks/UserContext";

export const Home = () => {
    const [name, setName] = useState('');
    const { userInfo } = useContext(UserContext);
    const [navigate, setNavigate] = useState(false);
    const imgPic = require('../img/pic.png');
    const imgAtt = require('../img/attendance.png');
    const imgRep = require('../img/refresh.png');
    const imgFront = require('../img/front.png');

    useEffect(() => {
        if (userInfo == null){
            setNavigate(true);
        }
        else {
            setName(userInfo?.name);
        }
    }, [userInfo]);

    if (navigate) {
        return <Navigate to="/login"/>;
    }

    return <div className="form-signin mt-2 text-center">
        <img
            src={ ( (userInfo?.avatar) ? userInfo?.avatar : imgPic ) }
            style={{width: 80, height: 80, borderRadius: 80/ 2}} 
            alt="avatar"
        />
        <h3>Hi, {name}</h3>

        <img src={imgFront} style={{width:'100%'}} alt=""/>

        <div className="row my-3">
            <div className="col-6">
                <a className="btn menubutton" href="/attend">
                    <img
                        src={ imgAtt }
                        style={{width: 120, height: 120, borderRadius: 45}} 
                        alt="attendance"
                    />
                    <div className="nav-link px-2 text-primary h5">Attendance</div>
                </a>
            </div>
            <div className="col-6">
                <a className="btn menubutton" href="/report">
                    <img
                        src={ imgRep }
                        style={{width: 120, height: 120, borderRadius: 45}} 
                        alt="attendance report"
                    />
                    <div className="nav-link px-2 text-primary h5">Report</div>
                </a>
            </div>
        </div>
    </div>
}